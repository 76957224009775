// studentMst.js

const studentMst = [
    {
        StudentGuidId: "f65a8a7d-5f42-4bc2-81a3-027e05b8e7f1",
        StudentId: "S1001",
        SchoolDistrictId: "71aef82b-8f82-4f44-b19d-05d3b82dfb3e",
        SchoolId: "d47f9a7e-3b25-4b62-881c-b6a5b8b3f4a5",
        SchoolName: "Central High School",
        Homeroom: "101",
        StudentNumber: "CHS2023-001",
        FirstName: "Maximus",
        LastName: "Thunderbolt",
        GradeID: "10",
        BirthDate: "2008-05-12",
        JoiningDate: "2023-09-01",
        Phone: "+12345678901",
        Email: "maximus.thunderbolt@school.edu",
        IsActive: true,
        CreatedOn: "2024-10-01T08:00:00Z",
        CreatedBy: "3f47f49a-d9a4-4f7c-bb4b-afe4a0f1e48e",
        LastUpdatedOn: "2024-11-01T08:15:00Z",
        LastUpdatedBy: "3f47f49a-d9a4-4f7c-bb4b-afe4a0f1e48e",
        Gender: "Male",
        Foster: false,
        Ethnicity: "Caucasian",
        isDC: false,
        DCType: null,
        GuardianEmail: "storm.thunderbolt@parentmail.com",
        isStaff: false,
        MealStatus: "F"  // Free (example status)
    },
    {
        StudentGuidId: "d7e5d9e8-3e52-4d2c-b2f0-0c4e0e4d3a12",
        StudentId: "S1002",
        SchoolDistrictId: "71aef82b-8f82-4f44-b19d-05d3b82dfb3e",
        SchoolId: "d47f9a7e-3b25-4b62-881c-b6a5b8b3f4a5",
        SchoolName: "Central High School",
        Homeroom: "102",
        StudentNumber: "CHS2023-002",
        FirstName: "Luna",
        LastName: "Stardust",
        GradeID: "10",
        BirthDate: "2008-09-15",
        JoiningDate: "2023-09-01",
        Phone: "+12345678902",
        Email: "luna.stardust@school.edu",
        IsActive: true,
        CreatedOn: "2024-10-01T08:00:00Z",
        CreatedBy: "3f47f49a-d9a4-4f7c-bb4b-afe4a0f1e48e",
        LastUpdatedOn: "2024-11-01T12:30:00Z",
        LastUpdatedBy: "3f47f49a-d9a4-4f7c-bb4b-afe4a0f1e48e",
        Gender: "Female",
        Foster: false,
        Ethnicity: "Hispanic",
        isDC: false,
        DCType: null,
        GuardianEmail: "nova.stardust@parentmail.com",
        isStaff: false,
        MealStatus: "P"  // Paid
    },
    {
        StudentGuidId: "a6e2e1c2-2b5f-4e98-9e3a-b7c9b9a9a6f8",
        StudentId: "S1003",
        SchoolDistrictId: "71aef82b-8f82-4f44-b19d-05d3b82dfb3e",
        SchoolId: "d47f9a7e-3b25-4b62-881c-b6a5b8b3f4a5",
        SchoolName: "Central High School",
        Homeroom: "103",
        StudentNumber: "CHS2023-003",
        FirstName: "Blaze",
        LastName: "Inferno",
        GradeID: "10",
        BirthDate: "2008-02-27",
        JoiningDate: "2023-09-01",
        Phone: "+12345678903",
        Email: "blaze.inferno@school.edu",
        IsActive: true,
        CreatedOn: "2024-10-02T08:00:00Z",
        CreatedBy: "3f47f49a-d9a4-4f7c-bb4b-afe4a0f1e48e",
        LastUpdatedOn: "2024-11-01T08:45:00Z",
        LastUpdatedBy: "3f47f49a-d9a4-4f7c-bb4b-afe4a0f1e48e",
        Gender: "Male",
        Foster: false,
        Ethnicity: "African American",
        isDC: false,
        DCType: null,
        GuardianEmail: "flare.inferno@parentmail.com",
        isStaff: false,
        MealStatus: "R"  // Reduced
    },
    // Add additional test students as needed
];

export default studentMst;
