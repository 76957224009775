const feedConfig = [
    {
        category: "Central High School",
        feeds: [
            {
                name: "Cafeteria Cam",
                embedCode: `<iframe width="780" height="439" src="https://www.youtube.com/embed/PLACEHOLDER1" title="Central High School Cafeteria" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,
                description: "Live view of the Central High School cafeteria."
            },
            {
                name: "Front Door Cam",
                embedCode: `<iframe width="1280" height="720" src="https://www.youtube.com/embed/jMVYZ9uMkwI" title="Oxford Martin School Webcam - Broad Street, Oxford" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
                description: "Security camera at the front entrance of Central High School."
            },
            {
                name: "Gym Cam",
                embedCode: `<iframe width="780" height="439" src="https://www.youtube.com/embed/PLACEHOLDER3" title="Central High School Gym" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,
                description: "Live view of the Central High School gymnasium."
            }
        ]
    },
    {
        category: "Westside Elementary",
        feeds: [
            {
                name: "Cafeteria Cam",
                embedCode: `<iframe width="780" height="439" src="https://www.youtube.com/embed/PLACEHOLDER4" title="Westside Elementary Cafeteria" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,
                description: "Live view of the Westside Elementary cafeteria."
            },
            {
                name: "Front Door Cam",
                embedCode: `<iframe width="1280" height="720" src="https://www.youtube.com/embed/jMVYZ9uMkwI" title="Oxford Martin School Webcam - Broad Street, Oxford" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
                description: "Security camera at the front entrance of Westside Elementary."
            },
            {
                name: "Gym Cam",
                embedCode: `<iframe width="780" height="439" src="https://www.youtube.com/embed/PLACEHOLDER6" title="Westside Elementary Gym" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,
                description: "Live view of the Westside Elementary gymnasium."
            }
        ]
    },
    {
        category: "Northview Middle School",
        feeds: [
            {
                name: "Cafeteria Cam",
                embedCode: `<iframe width="780" height="439" src="https://www.youtube.com/embed/PLACEHOLDER7" title="Northview Middle School Cafeteria" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,
                description: "Live view of the Northview Middle School cafeteria."
            },
            {
                name: "Front Door Cam",
                embedCode: `<iframe width="1280" height="720" src="https://www.youtube.com/embed/jMVYZ9uMkwI" title="Oxford Martin School Webcam - Broad Street, Oxford" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
                description: "Security camera at the front entrance of Northview Middle School."
            },
            {
                name: "Gym Cam",
                embedCode: `<iframe width="780" height="439" src="https://www.youtube.com/embed/PLACEHOLDER9" title="Northview Middle School Gym" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,
                description: "Live view of the Northview Middle School gymnasium."
            }
        ]
    },
    {
        category: "Southside High School",
        feeds: [
            {
                name: "Cafeteria Cam",
                embedCode: `<iframe width="780" height="439" src="https://www.youtube.com/embed/PLACEHOLDER10" title="Southside High School Cafeteria" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,
                description: "Live view of the Southside High School cafeteria."
            },
            {
                name: "Front Door Cam",
                embedCode: `<iframe width="1280" height="720" src="https://www.youtube.com/embed/jMVYZ9uMkwI" title="Oxford Martin School Webcam - Broad Street, Oxford" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
                description: "Security camera at the front entrance of Southside High School."
            },
            {
                name: "Gym Cam",
                embedCode: `<iframe width="780" height="439" src="https://www.youtube.com/embed/PLACEHOLDER12" title="Southside High School Gym" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,
                description: "Live view of the Southside High School gymnasium."
            }
        ]
    },
    {
        category: "Eastbrook Academy",
        feeds: [
            {
                name: "Cafeteria Cam",
                embedCode: `<iframe width="780" height="439" src="https://www.youtube.com/embed/PLACEHOLDER13" title="Eastbrook Academy Cafeteria" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,
                description: "Live view of the Eastbrook Academy cafeteria."
            },
            {
                name: "Front Door Cam",
                embedCode: `<iframe width="1280" height="720" src="https://www.youtube.com/embed/jMVYZ9uMkwI" title="Oxford Martin School Webcam - Broad Street, Oxford" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
                description: "Security camera at the front entrance of Eastbrook Academy."
            },
            {
                name: "Gym Cam",
                embedCode: `<iframe width="780" height="439" src="https://www.youtube.com/embed/PLACEHOLDER15" title="Eastbrook Academy Gym" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,
                description: "Live view of the Eastbrook Academy gymnasium."
            }
        ]
    }
];

export default feedConfig;
