import React from 'react';
import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Header from '../../component-files/Headers/Header';

const Dashboard = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Box m="20px">
            {/* HEADER */}
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="DASHBOARD" subtitle="Welcome to your dashboard" />
            </Box>

            {/* Task Board Section Placeholder */}
            <Box
                mt="20px"
                backgroundColor={colors.primary[400]}
                p="20px"
                borderRadius="8px"
            >
                <Typography variant="h5" fontWeight="600" color={colors.grey[100]}>
                    Task Board
                </Typography>
                {/* Kanban component removed */}
            </Box>
        </Box>
    );
};

export default Dashboard;
