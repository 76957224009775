// src/scenes/videofeeds/index.js

import React, { useState } from "react";
import { Box, Typography, IconButton } from "@mui/material";
import ApiVideoSidebar from '../../component-files/apis/ApiVideoSidebar';
import CloseIcon from "@mui/icons-material/Close";

// URL of the TekVisions logo
const tekvisionsLogoUrl = "https://tekvisionsp.wpenginepowered.com/wp-content/uploads/2017/11/TekVisions-Logo.png";

const VideoFeeds = () => {
    const [selectedFeed, setSelectedFeed] = useState(null);

    // Handle feed selection from sidebar
    const handleOpenFeed = (url, name) => {
        setSelectedFeed({ url, name });
    };

    // Close the selected feed
    const handleCloseFeed = () => {
        setSelectedFeed(null);
    };

    return (
        <Box display="flex">
            {/* Sidebar with video feeds */}
            <ApiVideoSidebar onFeedSelect={handleOpenFeed} />

            {/* Main content area with watermark and customized text color */}
            <Box
                flex={1}
                p={3}
                sx={{
                    backgroundColor: "#f4f6f8",
                    minHeight: "100vh",
                    position: "relative",
                    color: "#00274D" // Darkest blue color for text
                }}
            >
                {/* Watermark */}
                <Box
                    component="img"
                    src={tekvisionsLogoUrl}
                    alt="TekVisions Logo"
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: "50%",
                        height: "auto",
                        opacity: 0.3,
                        zIndex: 0,
                    }}
                />

                {/* Title and selected feed display */}
                <Typography variant="h4" gutterBottom sx={{ position: "relative", zIndex: 1 }}>
                    Live Video Feeds
                </Typography>

                {/* Iframe for selected feed */}
                {selectedFeed && (
                    <Box
                        sx={{
                            position: "fixed",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: "80%",
                            height: "80%",
                            backgroundColor: "#fff",
                            boxShadow: 3,
                            borderRadius: "8px",
                            zIndex: 10,
                            overflow: "hidden",
                        }}
                    >
                        <IconButton
                            onClick={handleCloseFeed}
                            sx={{
                                position: "absolute",
                                top: 8,
                                right: 8,
                                backgroundColor: "rgba(255, 255, 255, 0.8)",
                                zIndex: 11,
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <iframe
                            src={selectedFeed.url}
                            title={selectedFeed.name}
                            style={{ width: "100%", height: "100%", border: "none" }}
                        />
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default VideoFeeds;
