// src/scenes/students/index.js

import React from "react";
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import studentMst from "../../data/studentMst"; // Import student data

const StudentTable = () => {
    // Extract column headers dynamically from the first student object
    const columns = Object.keys(studentMst[0]);

    return (
        <Box p={3} sx={{ backgroundColor: "#f4f6f8", minHeight: "100vh" }}>
            <Typography variant="h4" gutterBottom>
                Student Information
            </Typography>
            <TableContainer component={Paper}>
                <Table>
                    {/* Table Header */}
                    <TableHead>
                        <TableRow>
                            {columns.map((column, index) => (
                                <TableCell key={index} align="center" style={{ fontWeight: "bold" }}>
                                    {column}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>

                    {/* Table Body */}
                    <TableBody>
                        {studentMst.map((student, index) => (
                            <TableRow key={index}>
                                {columns.map((column) => (
                                    <TableCell key={column} align="center">
                                        {student[column] ? student[column].toString() : "N/A"}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default StudentTable;
