import React, { useState, useEffect } from "react";
import { Box, Typography, IconButton, Collapse, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ProSidebar, Menu, MenuItem as SidebarMenuItem } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { Link } from "react-router-dom";
import { tokens } from "../../theme";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import ScheduleOutlinedIcon from "@mui/icons-material/ScheduleOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";

const Item = ({ title, to, icon, selected, setSelected }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    return (
        <SidebarMenuItem
            active={selected === title}
            style={{ color: colors.grey[100] }}
            onClick={() => setSelected(title)}
            icon={icon}
        >
            <Typography>{title}</Typography>
            <Link to={to} />
        </SidebarMenuItem>
    );
};

const Sidebar = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [isCollapsed, setIsCollapsed] = useState(isMobile);
    const [selected, setSelected] = useState("Dashboard");
    const [timeTrackingOpen, setTimeTrackingOpen] = useState(false);
    const [locationTrackingOpen, setLocationTrackingOpen] = useState(false);
    const [emergencyToolsOpen, setEmergencyToolsOpen] = useState(false);

    useEffect(() => {
        setIsCollapsed(isMobile);
    }, [isMobile]);

    return (
        <Box
            sx={{
                "& .pro-sidebar-inner": { background: `${colors.primary[400]} !important` },
                "& .pro-icon-wrapper": { backgroundColor: "transparent !important" },
                "& .pro-inner-item": { padding: "5px 35px 5px 20px !important" },
                "& .pro-inner-item:hover": { color: "#868dfb !important" },
                "& .pro-menu-item.active": { color: "#6870fa !important" },
            }}
        >
            <ProSidebar collapsed={isCollapsed}>
                <Menu iconShape="square">
                    <SidebarMenuItem
                        onClick={() => setIsCollapsed(!isCollapsed)}
                        icon={<MenuOutlinedIcon />}
                        style={{ margin: "10px 0 20px 0", color: colors.grey[100] }}
                    >
                        {!isCollapsed && (
                            <Box display="flex" justifyContent="space-between" alignItems="center" ml="15px">
                                <Typography variant="h3" color={colors.grey[100]}>Admin Panel</Typography>
                                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                                    <MenuOutlinedIcon />
                                </IconButton>
                            </Box>
                        )}
                    </SidebarMenuItem>

                    {/* Dashboard */}
                    <Item title="Dashboard" to="/dashboard" icon={<HomeOutlinedIcon />} selected={selected} setSelected={setSelected} />

                    {/* Staff Time Tracking Section */}
                    <Box mb="10px">
                        <SidebarMenuItem
                            icon={<ScheduleOutlinedIcon />}
                            onClick={() => setTimeTrackingOpen(!timeTrackingOpen)}
                            style={{ color: colors.grey[300], paddingLeft: "20px" }}
                        >
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Typography variant="h6" color={colors.grey[300]}>Staff Time Tracking</Typography>
                                <IconButton>
                                    {timeTrackingOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                </IconButton>
                            </Box>
                        </SidebarMenuItem>
                        <Collapse in={timeTrackingOpen}>
                            <Box pl="20px">
                                <Item title="Time Entry" to="/time-entry" icon={<ScheduleOutlinedIcon />} selected={selected} setSelected={setSelected} />
                                <Item title="Leave Requests" to="/leave-requests" icon={<ScheduleOutlinedIcon />} selected={selected} setSelected={setSelected} />
                                <Item title="Overtime Tracking" to="/overtime-tracking" icon={<ScheduleOutlinedIcon />} selected={selected} setSelected={setSelected} />
                            </Box>
                        </Collapse>
                    </Box>

                    {/* Student Location Tracking Section */}
                    <Box mb="10px">
                        <SidebarMenuItem
                            icon={<PeopleAltOutlinedIcon />}
                            onClick={() => setLocationTrackingOpen(!locationTrackingOpen)}
                            style={{ color: colors.grey[300], paddingLeft: "20px" }}
                        >
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Typography variant="h6" color={colors.grey[300]}>Student Location Tracking</Typography>
                                <IconButton>
                                    {locationTrackingOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                </IconButton>
                            </Box>
                        </SidebarMenuItem>
                        <Collapse in={locationTrackingOpen}>
                            <Box pl="20px">
                                <Item title="Daily Attendance" to="/daily-attendance" icon={<PeopleAltOutlinedIcon />} selected={selected} setSelected={setSelected} />
                                <Item title="Absence Reporting" to="/absence-reporting" icon={<PeopleAltOutlinedIcon />} selected={selected} setSelected={setSelected} />
                                <Item title="Classroom & Site Tracking" to="/site-tracking" icon={<MapOutlinedIcon />} selected={selected} setSelected={setSelected} />
                                <Item title="Student Schedule Import" to="/schedule-import" icon={<PeopleAltOutlinedIcon />} selected={selected} setSelected={setSelected} />
                            </Box>
                        </Collapse>
                    </Box>

                    {/* AI Video Monitoring Hub */}
                    <Box mb="10px">
                        <SidebarMenuItem
                            icon={<CameraAltOutlinedIcon />}
                            onClick={() => setEmergencyToolsOpen(!emergencyToolsOpen)}
                            style={{ color: colors.grey[300], paddingLeft: "20px" }}
                        >
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Typography variant="h6" color={colors.grey[300]}>AI Video Monitoring Hub</Typography>
                                <IconButton>
                                    {emergencyToolsOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                </IconButton>
                            </Box>
                        </SidebarMenuItem>
                        <Collapse in={emergencyToolsOpen}>
                            <Box pl="20px">
                                <Item title="Video Feeds" to="/video-feeds" icon={<CameraAltOutlinedIcon />} selected={selected} setSelected={setSelected} />
                                <Item title="Incident Detection" to="/incident-detection" icon={<WarningAmberOutlinedIcon />} selected={selected} setSelected={setSelected} />
                            </Box>
                        </Collapse>
                    </Box>

                    {/* Settings Section */}
                    <Box mb="10px">
                        <SidebarMenuItem
                            icon={<SettingsOutlinedIcon />}
                            style={{ color: colors.grey[300], paddingLeft: "20px" }}
                        >
                            <Typography variant="h6" color={colors.grey[300]}>Settings</Typography>
                        </SidebarMenuItem>
                        <Box pl="20px">
                            <Item title="User Management" to="/user-management" icon={<SettingsOutlinedIcon />} selected={selected} setSelected={setSelected} />
                            <Item title="System Settings" to="/system-settings" icon={<SettingsOutlinedIcon />} selected={selected} setSelected={setSelected} />
                        </Box>
                    </Box>
                </Menu>
            </ProSidebar>
        </Box>
    );
};

export default Sidebar;
