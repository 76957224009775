import React, { useState } from "react";
import Draggable from "react-draggable";
import { Box, Button, Typography, IconButton, Avatar } from "@mui/material";
import { useTheme } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import feedConfig from "../../data/feedConfig";

const VideoFeedSidebar = () => {
    const theme = useTheme();
    const [iframes, setIframes] = useState([]);
    const [openCategories, setOpenCategories] = useState({});

    const openIframe = (url, name) => {
        setIframes((prev) => [
            ...prev,
            { id: Date.now(), url, name, size: { width: "600px", height: "400px" } },
        ]);
    };

    const closeIframe = (id) => setIframes((prev) => prev.filter((iframe) => iframe.id !== id));

    const toggleCategory = (category) => {
        setOpenCategories((prev) => ({
            ...prev,
            [category]: !prev[category],
        }));
    };

    return (
        <Box
            sx={{
                width: "300px",
                padding: theme.spacing(2),
                backgroundColor: theme.palette.background.paper,
                color: theme.palette.text.primary,
                borderRight: `1px solid ${theme.palette.divider}`,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                boxShadow: theme.shadows[4],
                overflowY: "auto",
            }}
        >
            <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold" }}>
                Live Video Feeds
            </Typography>

            {feedConfig.map((category, index) => (
                <Box key={index} width="100%" mb={2}>
                    <Button
                        fullWidth
                        onClick={() => toggleCategory(category.category)}
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            color: theme.palette.text.primary,
                            fontWeight: "bold",
                            textTransform: "none",
                            backgroundColor: theme.palette.background.default,
                            border: `1px solid ${theme.palette.divider}`,
                            mb: 1,
                        }}
                    >
                        {category.category}
                        {openCategories[category.category] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </Button>

                    {openCategories[category.category] &&
                        category.feeds.map((feed) => (
                            <Button
                                key={feed.name}
                                onClick={() => openIframe(feed.url, feed.name)}
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    mb: 1,
                                    textTransform: "none",
                                    backgroundColor: theme.palette.background.default,
                                    color: theme.palette.text.primary,
                                    border: `1px solid ${theme.palette.divider}`,
                                    justifyContent: "flex-start",
                                    width: "100%",
                                    height: "50px",
                                    "&:hover": { backgroundColor: theme.palette.action.hover },
                                }}
                            >
                                {/* Live preview thumbnail */}
                                <Avatar
                                    variant="circular"
                                    src={feed.url}
                                    alt={feed.name}
                                    sx={{
                                        width: 40,
                                        height: 40,
                                        mr: 2,
                                    }}
                                />
                                <Typography variant="body1">{feed.name}</Typography>
                            </Button>
                        ))}
                </Box>
            ))}

            {/* Draggable iframes for video feeds */}
            {iframes.map((iframe) => (
                <Draggable key={iframe.id}>
                    <Box
                        sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            backgroundColor: "#fff",
                            border: "2px solid #ccc",
                            zIndex: 1000,
                            width: iframe.size.width,
                            height: iframe.size.height,
                            boxShadow: theme.shadows[10],
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                padding: "5px",
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.primary.contrastText,
                                fontWeight: "bold",
                            }}
                        >
                            <Typography variant="h6" sx={{ ml: 2 }}>
                                {iframe.name}
                            </Typography>
                            <IconButton onClick={() => closeIframe(iframe.id)} aria-label="close iframe">
                                <CloseIcon sx={{ color: theme.palette.primary.contrastText }} />
                            </IconButton>
                        </Box>
                        <iframe
                            src={iframe.url}
                            title={iframe.name}
                            style={{ width: "100%", height: "calc(100% - 40px)", border: "none" }}
                        />
                    </Box>
                </Draggable>
            ))}
        </Box>
    );
};

export default VideoFeedSidebar;
